#root {
    height: 100%;
    background: url(../../assets/images/bg-login.jpg) no-repeat;
    background-size: cover;
}

.login-background {
    background: url(../../assets/images/img-login.png) no-repeat;
    background-size: 600px;
    background-position: center;
}

.vertical-center {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 100%;
    padding: 16px;
}

.login-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 40px;

    position: static;
    width: 380px;
    /* height: 420px; */
    left: 0px;
    /* top: 200.36px; */

    /* Primary/White */

    background: #FFFFFF;

    /* Inside auto layout */

    flex: none;
    /* order: 1;
    flex-grow: 0;
    margin: 40px auto */
}

.login-logo {
    position: static;
    width: 64px;
    height: 63.36px;
    top: 0px;
    background: url(../../assets/images/logo-man.png);
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 25px auto;
}

.login-title {
    position: static;
    width: 269px;
    height: 40px;
    left: 45px;
    top: 0px;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 40px;
    color: #161616;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 8px auto;
}

.login-subtitle {
    position: static;
    width: 359px;
    height: 24px;
    left: 0px;
    top: 48px;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #12784A;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 8px auto;
}

.login .ant-input {
    /* text-overflow: ellipsis;
    padding: 8px 16px; */
    /* border-radius: 8px; */
}

.login .ant-input-affix-wrapper {
    /* text-overflow: ellipsis;
    padding: 8px 16px; */
    /* border-radius: 8px; */
}

.login label {
    position: static;
    width: 52px;
    height: 24px;
    left: 0px;
    top: 0px;

    /* Heading/Small */

    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    letter-spacing: 0.1px;

    color: #000000;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 10px 0px;
}

.login .ant-checkbox+span {
    padding-right: 8px;
    padding-left: 8px;
    font-family: 'Work Sans';
}

.login .login-btn {
    font-family: 'Work Sans';
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 24px;

    position: static;
    width: 100%;
    /* height: 56px; */
    left: 40px;
    top: 324px;

    /* Secondary/Green */

    background: #12784A;
    color: #fff;
    border-radius: 8px;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
    border: 1px solid #158b67;
}

.login-btn:hover,
.login-btn:active,
.login-btn:focus {
    background-color: #158b67;
    color: #fff;
    border: 1px solid #158b67;
}

.login-footer {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #12784A;
    text-align: center;
    margin: 16px;
}

.section-login {
    width: 100%;
    height: 100%;
    /* border: 1px solid #2d2d2d; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.div-login {
    box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    color: #fff;
    background: #fff;
    padding: 12px;
    display: inline-block;
}

@media only screen and (max-width: 1024px) {
    .login-background{
        background-size: 80%;
    }

    .login-form{
        width: 420px;
        padding: 24px 16px;
    }

    .login-subtitle{
        width: 420px;
        text-align: center;
    }

    .login-title{
        width: 420px;
        text-align: center;
    }
}

@media only screen and (max-width: 768px) {
    .login-background{
        background-size: 80%;
    }

    .login-form{
        width: 320px;
        padding: 24px 16px;
    }

    .login-subtitle{
        width: 320px;
        text-align: center;
    }

    .login-title{
        width: 320px;
        text-align: center;
    }

    .login .login-btn{
        height: 0;
        border-radius: 4px;
    }
}

@media only screen and (max-width: 480px) {
    .login-background {
        display: none;
    }

    .login-form {
        width: 100%;
        height: 100%;
        padding: 32px 24px;
    }

    .login-title {
        text-align: center;
        width: 100%;
        font-size: 18px;
    }

    .login-subtitle {
        text-align: center;
        width: 190px;
        font-size: 10px;
    }

    .div-login {
        padding: 0px;
        margin: 8px;
        width: 95%;
    }
}