/* GRID SIDEBAR */
.sidebar-icon {
    grid-area: sidebar-icon;
    text-align: center !important;
}

.sidebar-title {
    grid-area: sidebar-title;
    font-size: 16px;
    color: #fff;
    font-weight: bold;
}

.sidebar-desc {
    grid-area: sidebar-desc;
    font-size: 12px;
    font-weight: 500;
    color: #fff;

}

.sidebar-grid-container {
    display: grid;
    grid-template-areas:
        'sidebar-icon sidebar-title sidebar-title sidebar-title sidebar-title sidebar-title'
        'sidebar-icon sidebar-desc sidebar-desc sidebar-desc sidebar-desc sidebar-desc';
    gap: 0px;
    font-weight: 400;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.45);
}

.sidebar-grid-container>div {
    text-align: left;
}

.sidebar-profile {
    background-image: url("../../assets/images/bg-profile.jpg");
    height: 140px;
    padding: 28px 24px 24px 24px;
    background-position: center;
}







/* AND CUSTOM */

.ant-layout-sider {
    background: #ffffff;
}

.ant-layout-sider-trigger {
    display: none !important;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background: #10B5821A;
}

.ant-menu-title-content a {
    color: #0d5735 !important;
}

.ant-menu-item-selected a,
.ant-menu-item-selected a:hover {
    color: #0d5735;
    font-weight: bold;
}

.ant-menu-item-selected {
    color: #0d5735;
}

.ant-menu-item:active,
.ant-menu-submenu-title:active {
    background: #e5f3ef;
}

.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
    border-color: #0d5735;
}

.ant-menu-item-selected .icon-sidebar {
    color: #12784a !important;
}

@media only screen and (max-width: 480px) {
    aside.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed {
        display: none;
    }
}