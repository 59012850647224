.react-html5-camera-photo>img, .react-html5-camera-photo>video {
    width: 100% !important;
}

.btn-close {
    position: absolute;
    right: -10px;
    top: -10px;
    background: white;
    color: red;
    padding: 0px;
    border-color: white;
    border-radius: 100% !important;
}


.bg-camera {
    height: 100%;
    position: fixed;
    top: 0;
    background: #0000005c;
    width: 100%;
    left: 0;
    z-index: +999;
    text-align: right;
}

.content-camera {
    z-index: +999;
    margin: auto;
    margin-top: 60px;
    width: 80%;
    /* min-height: 40%; */
    border: 8px solid #00000031;
    position: relative;
}

.react-html5-camera-photo {
    margin-bottom: -5px !important;
}